import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21a318e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-body org-tasks-tab-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskCollectionNew = _resolveComponent("TaskCollectionNew")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TaskCollectionNew, { organization: $props.organization }, null, 8, ["organization"])
  ]))
}