
import TaskCollectionNew from "../tasks/TaskList.vue"

export default {
  name: "OrganizationTasks",
  components: { TaskCollectionNew },
  props: {
    organization: Object,
  },
  setup() {},
}
